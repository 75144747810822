/* Font Imports */
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Public+Sans:wght@400;500;600;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fbfcfe;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.apptheme {
  margin-top: 0rem;
  margin-bottom: 0rem;
  flex: 1;
}
